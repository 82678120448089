import axios from 'axios';
import { http } from './index';
import settings from '@/settings';

const exchangeApi = {
  getJwt: (data: any) =>
    http.post(`/core/access-tokens/exchange`, data, {
      withCredentials: true,
    }),
};

const codeApi = {
  submit: (...data: any[]) =>
    http.post('/functions/wechat-authenticity-task', ...data),
  status: (...data: any[]) => (axios as any).get(...data),
};

const thingApi = {
  getPublic: (organizationId: string, interactionId: string, qs?: any) =>
    http.get(`/public/things/${organizationId}/${interactionId}`, {
      withCredentials: true,
      params: qs,
    }),
};

const taskApi = {
  get: (id: string) => http.get(`/core/tasks/${id}`),
  exchangeToken: (token: string, ...data: any[]) =>
    http.post('/core/executions', ...data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }),
  getExecution: (id: string, token: string) =>
    http.get(`/core/executions/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }),
  getPublicExecution: (id: string, token: string) =>
    http.get(`/public/executions/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }),
  updateExecution: (id: string, token: string, data: any) =>
    http.patch(`/core/executions/${id}`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }),
  processExecution: (id: string, token: string) =>
    http.post(
      `/core/executions/${id}/process`,
      {},
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        withCredentials: true, // this endpoint sets a cookie
      },
    ),
};

const sharedApi = {
  submit: (orgId: string, interactionId: string, ...data: any[]) =>
    http.post(`/public/views/${orgId}/${interactionId}`, ...data),
};

const qrApi = {
  getViewResult: (orgId: string, interactionId: string) =>
    http.get(`/public/views/results/${orgId}/${interactionId}`),
};

const previewApi = {
  getPreviewResult: (
    orgId: string,
    thingId: string,
    itemId: string,
    qs: any,
  ) => {
    return http.get(`/public/things/preview/${orgId}/${thingId}`, {
      withCredentials: true,
      params: {
        ...qs,
        ...(itemId && {
          itemId: itemId,
        }),
      },
    });
  },
};

const locationApi = {
  get: (): Promise<any> => {
    return new Promise((resolve, reject) => {
      const timeoutMs = 5000;
      const cancelToken = axios.CancelToken.source();
      const timeout = setTimeout(() => {
        cancelToken.cancel('timeout');
        reject();
      }, timeoutMs);
      axios
        .post(
          'https://www.googleapis.com/geolocation/v1/geolocate',
          { considerIp: true },
          {
            timeout: timeoutMs,
            cancelToken: cancelToken.token,
            params: { key: settings.googleGeoKey },
          },
        )
        .then((locationData) => {
          resolve({
            accuracy: String(locationData.data.accuracy),
            latitude: Number(locationData.data.location.lat),
            longitude: Number(locationData.data.location.lng),
          });
        })
        .catch((err) => {
          reject(err);
        })
        .finally(() => {
          clearTimeout(timeout);
        });
    }).catch(() => {
      return new Promise((resolve, reject) => {
        try {
          navigator.geolocation.getCurrentPosition(
            (position) => {
              resolve({
                latitude: Number(position.coords.latitude),
                longitude: Number(position.coords.longitude),
              });
            },
            (err) => {
              reject(err);
            },
            {
              timeout: 5000,
            },
          );
        } catch (err) {
          reject(err);
        }
      });
    });
  },
};

const captchaApi = {
  getResult: () =>
    http.get('/auth/captcha', {
      withCredentials: true,
    }),

  submit: (source: string, input: string) =>
    http.get(`/auth/captcha?source=${source}&input=${input}`, {
      withCredentials: true,
    }),
};

const organizationApi = {
  get: (orgId: string, token: string) =>
    http.get(`/core/organizations/${orgId}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }),
};

export {
  exchangeApi,
  codeApi,
  taskApi,
  thingApi,
  sharedApi,
  qrApi,
  previewApi,
  locationApi,
  captchaApi,
  organizationApi,
};
