import '@/mixins';
import '@/polyfills';
import store from '@/store';
import Vue from 'vue';
import VueApollo from 'vue-apollo';
import AsyncComputed from 'vue-async-computed';
import VueCookies from 'vue-cookies';
import VueLazyLoad from 'vue-lazyload';
import VueMobileDetection from 'vue-mobile-detection';
import VueRouter from 'vue-router';
import { apolloProvider } from './api/graphql';
import App from './App.vue';
import i18n from './i18n';
import DashboardPlugin from './material-dashboard';
import './registerServiceWorker';
import router from './router';
import '@/mixins';
import '@/polyfills';
import * as VueGoogleMaps from 'vue2-google-maps';

Vue.config.productionTip = false;

// plugin setup
Vue.use(VueRouter);
Vue.use(DashboardPlugin);
Vue.use(VueApollo);
Vue.use(AsyncComputed);
Vue.use(VueCookies);
// Vue.use(VueCookies, {
//   expires: '7d',
//   path: '/',
//   domain: window.location.hostname,
// });
(Vue as any).use(VueMobileDetection);
Vue.use(VueLazyLoad);

Vue.$cookies.config('7d', '/', window.location.hostname);

Vue.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyA9dRmVSNPVfcH2Lagswxepv_VBZc7w6U0',
    libraries: 'places',
  },
  installComponents: true,
});

/* eslint-disable no-new */
new Vue({
  el: '#app',
  render: (h: any) => h(App),
  router,
  store,
  i18n,
  apolloProvider,
});
